<template>
  <div class="like__wrape" :class="{ active: show, return: !show }">
    <span class="like__text" :class="{ active: show, return: !show }">Like</span>
    <span class="like__icon" :class="{ active: show, return: !show }">
      <v-icon color="white">mdi-cards-heart</v-icon>
    </span>
  </div>

  <!-- <LikeCategories /> -->
</template>

<script>
import { computed } from "vue";
import "../LikeNotificationVideo/LikeNotificationVideo.scss";
export default {
  name: "LikeNotificationVideo",
  emits: ["score"],
  props: {
    show: Boolean,
    widthVideo: Number,
    heightVideo: Number,
  },
  setup(props) {

    const sizeStyle = computed(() => {
      return {
        width: `calc( ${props.widthVideo}px / 2 * .45)`,
        height: `calc( ${props.heightVideo}px / 2 * .3)`,
        left: `${props.interaction.left}%`,
        bottom: `calc(100% - ${props.interaction.bottom}%)`,
      };
    });

    return {
      sizeStyle,
    };
  },
};
</script>
