<template>
  <div v-for="interaction in interactions" :key="interaction">
    <component
      :is="componentSelect(interaction.details.type)"
      :id="interaction.details.id"
      :interaction="interaction"
      :widthVideo="widthVideo"
      :heightVideo="heightVideo"
      @show="notification"
      @plus="addVariantProduct"
    />
  </div>
</template>

<script>
import "./InteractionVideo.scss";
import BuyInteractionVideo from "@/components/BuyInteractionVideo";
import DetailsInteractionVideo from "@/components/DetailsInteractionVideo";
import OptionsInteractionVideo from "@/components/OptionsInteractionVideo";
import LikeNotificationVideo from "@/components/LikeNotificationVideo";
import CartNotificationVideo from "@/components/CartNotificationVideo";
import {ref} from "vue";

export default {
  name: "IneteractionTestVideo",
  components: {
    BuyInteractionVideo,
    DetailsInteractionVideo,
    OptionsInteractionVideo,
    LikeNotificationVideo,
    CartNotificationVideo
  },
  emits: ["assign"],
  props: {
    interactions: Object,
    widthVideo: Number,
    heightVideo: Number,
  },
  setup(props,context) {
    const activeNoti =  ref(false);
    const showCart = ref(false);
    const showNotification = ref(false);
    const countProducts = ref(0);
    const ruffles1 = ref(0);
    const ruffles2 = ref(0);
    const ruffles3 = ref(0);
    const ruffles4 = ref(0);

    const switchFn =
      (lookupObject, defaultCase = "_default") =>
      (expression) =>
        (lookupObject[expression] || lookupObject[defaultCase])();

    const filterInteraction = {
      product: () => "buy-interaction-video",
      info: () => "details-interaction-video",
      stop: () => "buy-interaction-video",
      question: () => "question",
      options: () => "options-interaction-video",
      default: () => "DefaultNotificationVideo",
    };

    const filterNotification = {
      product: () => "cart-notification-video",
      info: () => "like-notification-video",
      stop: () => "cart-notification-video",
      question: () => "question",
      options: () => "options-notification-video",
      default: () => "DefaultNotificationVideo",
    };

    const variableProducts = {
      1: () => (ruffles1.value += 1 ),
      2: () => (ruffles2.value += 1 ),
      3: () => (ruffles3.value += 1 ),
      4: () => (ruffles4.value += 1 ),
      default: () => ""
    }

    const typeCompoenet = switchFn(filterInteraction, "default");
    const typeNotification = switchFn(filterNotification, "default");
    const typeVariantProduct = switchFn(variableProducts,"default");

    function componentSelect(type) {
      return typeCompoenet(type);
    }

    function notificationSelect(type){
      return typeNotification(type);
    }

    function notification(type){
      if(type == "cart"){
        showCart.value = true;
        setTimeout(() => {
          countProducts.value++;
        }, 1600);
      }
      // else{showLike.value = true;}
     
      setTimeout(() => {
        showNotification.value = !showNotification.value;
        setTimeout(() => {
          showNotification.value = !showNotification.value;
        }, 1600);
      }, 250);   
    }

    function addVariantProduct(variantProduct){
      typeVariantProduct(variantProduct);
      context.emit("assign",[
          {"variant": 1,"score": ruffles1.value},
          {"variant": 2,"score": ruffles2.value},
          {"variant": 3,"score": ruffles3.value},
          {"variant": 4,"score": ruffles4.value},
        ].sort( (a,b) => {
          if(a.score > b.score) return -1;
          if(a.score < b.score) return 1;
          return 0;
        }) 
      );
    }

    return {
      activeNoti,
      showCart,
      showNotification,
      countProducts,
      componentSelect,
      notificationSelect,
      notification,
      addVariantProduct,
    };
  },
};
</script>
