<template>
  <div
    class="interaction"
    :id="id"
    :class="interactionsClass"
    :style="sizeStyle"
    @click="addCart"
  ></div>

  <div v-if="interaction.details.extras">
    <a
      class="btn"
      v-for="extra in interaction.details.extras"
      :key="extra"
      :class="[classExtra,extra.class]"
      :style="[
        sizeStyleButtons,
        {
          left: 'calc(100% - ' + extra.left + '%)',
          bottom: 'calc(100% - ' + extra.bottom + '%)',
        },
      ]"
      @click="extra.class == 'play' ? playVideo() : buySnack()"
    >
     <img 
     :src="pictureHover" 
     @mouseover="hover = !hover"
     @mouseleave="hover = !hover"
     >
      </a>
  </div>
</template>

<script>
import { computed, ref } from "vue";

import "@/components/BuyInteractionVideo/BuyInteractionVideo.scss";
export default {
  name: "BuyInteractionVideo",
  components: {
  },
  emits:["show","score"],
  props: {
    id: Number,
    widthVideo: Number,
    heightVideo: Number,
    interaction: Object,
  },
  setup(props) {
    const touch = ref(false);
    const countProducts = ref(0);
    const viewCart = ref(false);
    const viewLabel = ref(false);
    const hover = ref(false);

    const sizeStyle = computed(() => {
      return {
        width: `calc( ${props.widthVideo}px / 2 * .45)`,
        height: `calc( ${props.heightVideo}px / 2 * .3)`,
        left: `${props.interaction.left}%`,
        bottom: `calc(100% - ${props.interaction.bottom}%)`,
      };
    });

    const interactionsClass = computed(() => {
      return props.interaction.details.type == "stop"
        ? props.interaction.details.type
        : "int_video-cta int_video-cart " + props.interaction.details.type;
    });

    const classExtra = computed(() => {
      return props.interaction.details.type == "stop"
        ? "btn-continue"
        : "btn-option";
    });

    const sizeStyleButtons = computed(() => {
      return {
        // width: `calc( ${props.widthVideo}px - 85%)`,
        // height: `calc( ${props.heightVideo}px - 90% )`,
        width: `calc(var(--vw, 1vw) * 12)`,
        height: `calc(var(--vh, 1vh) * 1)`,
      };
    });
   
    const pictureHover = computed(() => {
        return hover.value ? 
              require('@/assets/'+ props.interaction.details.extras[0].hover) :
              require('@/assets/'+ props.interaction.details.extras[0].imageBtn);
    });

    function playVideo(){
      document.querySelector('.btn-continue.play').classList.remove('active');
      document.querySelector('.slider.player__seeker').style.display = "none";
      document.getElementById('play').style.display = 'none';
      let video = document.querySelector("video");
      video.currentTime += .5;
      video.play();
      video.muted = false;
    }

     return {
      touch,
      sizeStyle,
      sizeStyleButtons,
      interactionsClass,
      classExtra,
      viewCart,
      viewLabel,
      countProducts,
      hover,
      pictureHover,
      playVideo
    };
  },
};
</script>
