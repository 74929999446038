<template>
  <v-app>
    <v-main>
      <BackgroundPepsico v-if="showIntro" @start="play" />
      <transition name="fade">
        <!-- clase que se quito del iv de abajo fill-height-->
        <div v-if="showVideo" 
          class="d-flex aling-center align-sm-center align-md-center align-lg-center align-xl-center mx-auto flex-fill  size_video">
          <PlayerVideo :source="sourceData" :interactions="interactions" @recomendation="positions" />
        </div>
      </transition>
      <transition name="final-fade">
        <section v-if="showFinal" >
          <FinalVideo :dataSnacks="dataSnacks" />
        </section>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import PlayerVideo from "./components/PlayerVideo/PlayerVideo.vue";
import FinalVideo from "@/components/FinalVideo";
import BackgroundPepsico from "./components/BackgroundPepsico";
import { onMounted, ref, computed } from "vue";
import interactionData from "./interactions.json";
import snacks from "./snacks.json";

export default {
  name: "App",
  components: {
    PlayerVideo,
    FinalVideo,
    BackgroundPepsico,
  },
  setup() {
    const interactions = interactionData;
    const showIntro = ref(false);
    const showVideo = ref(false);
    const showFinal = ref(false);
    const dataSnacks = ref(null);
    const first = ref(null);
    const filterScore = snacks;
    const showDiv = ref(false);
    var d = document.documentElement;

    const sourceData = {
      src: "Choca_a_Checo.mp4",
      type: "video/mp4",
    };

    onMounted(() => {
      d.style.setProperty('--vw', (d.clientWidth || window.innerWidth) / 100 + 'px');
      d.style.setProperty('--vh', (d.clientHeight || window.innerHeight) / 100 + 'px');

      setTimeout(() => {
       showVideo.value = true;
      }, 200);
      
      window.onresize = () => {
        onWindowResize();
      };

    });

    function onWindowResize() {
      d.style.setProperty('--vw', (d.clientWidth || window.innerWidth) / 100 + 'px');
      d.style.setProperty('--vh', (d.clientHeight || window.innerHeight) / 100 + 'px');
    }

    const background = computed(() => {
      return {
        width: "100%",
        height: "100%",
      };
    });

    function play() {
      showIntro.value = false;
      showVideo.value = true;

      setTimeout(() => {
        document.querySelector("video").play();
      }, 200);
    }

    function positions(variants) {
      first.value = variants ==null ? 1 : variants[0].variant; 
      dataSnacks.value = filterScore.find((variant) => variant.variable_product === first.value);
      showVideo.value = true;
      showFinal.value = true;
    }
    
    return {
      sourceData,
      interactions,
      showIntro,
      showVideo,
      showFinal,
      dataSnacks,
      background,
      showDiv,
      first,
      play,
      positions,
    };
  },
};
</script>

<style>
:root {
  --vw: 0px;
  --vh: 0px;
}

html {
  overflow-y: hidden !important;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  transition: all 2s ease;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: all 3s ease;
}

.final-fade-enter-from {
  opacity: 0;
}

.final-fade-enter-to {
  opacity: 1;
}

.final-fade-enter-active {
  transition: all 4s ease;
}

.aling-center {
  align-items: center;
}

.v-main {
  background: #000000;
}

.size_video {
  height: 100%;
  width: calc(var(--vw, 1vw) * 80);
}

@media screen and (min-width: 800px){
  .size_video {
    height: auto;
    width: calc(var(--vw, 1vw) * 65);
  }

  
}

</style>
