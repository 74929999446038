<template>

 <div class="cart__wrape" @click="openShop">
  <div class="cart__add" :class="{'effect': showNotification,'return': !showNotification}">
    <span :class="viewStyle"> 
      <strong>Product added to cart </strong> 
    </span>
    <v-icon color="white" :class="{'effect': showNotification,'return': !showNotification}"> mdi-cart  </v-icon>
  </div>
  <span class="cart__count" :class="{'effect': showNotification,'return': !showNotification}">{{countProducts}}</span>
</div>

</template>

<script>
import { computed } from '@vue/runtime-core';

import "@/components/CartNotificationVideo/CartNotificationVideo.scss";

export default {
 name: 'CartNotificationVideo',
 props: {
  countProducts:Number ,
  showNotification:Boolean,
  widthVideo: Number,
  heightVideo: Number,
 },
 setup(props){

  const sizeStyle = computed(() => {
      return{
          transform: `scale(calc( (${props.widthVideo} - 300) / (${props.widthVideo} - ${props.heightVideo}) *.6 ))`
        };
    });

    const viewStyle = computed(() => {
      return props.viewLabel ? 'active' : '' ;  
    });

    function openShop(){
      document.querySelector('video').pause();
      const url = localStorage.getItem('cartUrl');
      setTimeout(() => {
        window.open(url, '_blank').focus();
      },200);
    }
    return {viewStyle,sizeStyle,openShop};
 }
}
</script>