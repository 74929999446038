<template>
  <div class="recomendation">
    <div class="recomendation__details">
      <div class="recomendation__image">
        <img :src="require('@/assets/'+ dataSnacks.snackFinalImage)" class="recomendation__copa" />
        <a :href="dataSnacks.snackUrl" target="_blank">
          <img 
            class="recomendation__compra"
            :src="pictureHover" 
            @mouseover="hover = !hover"
            @mouseleave="hover = !hover"
          >
        </a>
       
      </div>
    </div>
  </div>
  <a href="https://super.walmart.com.mx/browse/promociones-especiales/pepsico/ruffles/1940023_700005_4080051" target="_blank">
    <img 
      class="recomendation__compra_all"
      :src="pictureHoverAll" 
      @mouseover="hoverAll = !hoverAll"
      @mouseleave="hoverAll = !hoverAll"
    >
  </a>
</template>

<script>
import "../FinalVideo/FinalVideo.scss";
// import FireworksEffect from "../FireworksEffect/FireworksEffect.vue";
import { computed, ref } from "vue";

export default {
  name: "FinalVideo",
  // components:{FireworksEffect},
  props: {
    dataSnacks: {
      type: Object,
      requird: true,
    },
  },
  setup(props) {
    const hover = ref(false);
    const hoverAll = ref(false);

    function openShop() {
      window.open(props.dataSnacks.snackUrl, "_blank").focus();
    }

    const pictureHover = computed(() => {
        return hover.value ? 
              require('@/assets/COMPRAR_HOVER.png') :
              require('@/assets/COMPRAR.png');
    });

    const pictureHoverAll = computed(() => {
        return hoverAll.value ? 
              require('@/assets/ENLACE_HOVER.png') :
              require('@/assets/ENLACE.png');
    });
    
    return {
      hover,
      hoverAll,
      pictureHover,
      pictureHoverAll,
      openShop,
    };
  },
};
</script>
