<template>
  <div :id="id" class="cta interaction">

    <div class="cta__backgraound"  v-if="showDetails"  />
    <div
      ref="cta"
      class="cta__info-wrape"
      :style="[positionCta]" 
      v-if="showCta"
    >
      <img @click="addFavorites"
        :liked="isLike"
        :variable_product="interaction.details.variable_product"
        :style="[sizeImage, isLike ?  hiden : '']"
        :src="require('@/assets/'+ interaction.details.img_product)" 
        alt="" 
      >
      <img
        :style="[sizeImage, isLike ?  '' :  hiden ]"
        :src="require('@/assets/'+ interaction.details.img_explosion)"
        alt="" 
      >
    </div>

  </div>
</template>

<script>
import "@/components/DetailsInteractionVideo/DetailsInteractionVideo.scss";
import { computed, onMounted, ref } from "vue";
export default {
  name: "DetailsInteractionVideo",
  emits: ["show", "plus"],
  props: {
    id: Number,
    interaction: Object,
    widthVideo: Number,
    heightVideo: Number,
  },

  setup(props, context) {
    const showDetails = ref(false);
    const showCta = ref(true);
    const showLike = ref(false);
    const showEffect = ref(false);
    const isLike = ref(false);

    const favorites = [];

    const plusIcon = computed(() => {
      return "cta__info-plus";
    });

    const likeIcon = computed(() => {
      return "cta__info-like";
    });

    const positionCta = computed(() => {
      return {
        left: `${props.interaction.cta.left}%`,
        top: `${props.interaction.cta.top}%`,
      };
    });

    const detailsStyle = computed(() => {
      return {
        left: `${props.interaction.left}%`,
        top: `${props.interaction.top}%`,
      };
    });

    const sizeImage = computed(() => {
      return {
        height: `${props.interaction.details.height}px`,
        cursor: 'pointer',
      };
    });

    const hiden = computed(() => {
      return showDetails.value ? { display: "block" } : { display: "none" };
    });

    onMounted(() => {});

    function addFavorites(event) {
      favorites.push(props.interaction.details.id);
      showLike.value = true;
      context.emit("show", "like");
   
      if (event.currentTarget.attributes.liked.value == "false") {
        context.emit(
          "plus",
          Number(event.currentTarget.attributes.variable_product.value)
        );
        isLike.value = true;
      }
    }

    return {
      // transformCta,
      positionCta,
      detailsStyle,
      sizeImage,
      showDetails,
      showCta,
      showLike,
      showEffect,
      hiden,
      likeIcon,
      plusIcon,
      isLike,
      addFavorites,
    };
  },
};
</script>