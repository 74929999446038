import clamp from "lodash/clamp";

export function offsetX(el){
    const {left} = el.getBoundingClientRect();
    const {scrollLeft} =  document.documentElement;
    return left +scrollLeft;
}
export function getRelativeXPosition(e,target){
    const eloffsetX = offsetX(target);
    const elWidth = target.offsetWidth;
    const value = e.pageX - eloffsetX;
    return clamp(value,0,elWidth);
}


export function calculatePosition(objElement, strOffset){
    var iOffset = 0;
    if(objElement.offsetParent){
        do{
            iOffset += objElement[strOffset];
            objElement = objElement.offsetParent;
        }while (objElement);

        return iOffset;
    }

}