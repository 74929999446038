<template>
  <div :class="classes" @mousedown="onSliderMouseDown" @click="onSliderClick">
    <div class="slider__bar" ref="bar">
      <div class="slider__handle" ref="handle" :style="handleStyle"/>
      <div class="slider__fill" :style="fillStyle" />
      <slot name="bar" />
    </div>
</div>
</template>

<script>
import "@/components/SliderVideo/SliderVideo.scss";
import throttle from "lodash/throttle";
import {getRelativeXPosition} from "@/utils/helpers";
const lerp = require("lerp");

export default {
  name: "SliderVideo",
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1,
    },
    value: Number,
    disable: Boolean,
  },
  data() {
    return {
      isDragging: false,
      handleWidth: 0,
      barWidth: 0,
      dragTimeout: null,
    }
  },
  computed: {
    classes(){
      return {
        "slider": true,
        "slider--dragging": this.isDragging,
        "slider--disable": history.disable
      }
    },
    delta() {
      return this.value / this.max;
    },
    fillStyle() {
      return {
        transformOrigin: "left center",
        transform: `scaleX(${this.delta})`,
      };
    },
    handleStyle(){
        const {barWidth, delta, handleWidth } = this;
        return {
            transform: `translateX(${barWidth * delta - handleWidth * .5}px)`
        }
    }
  },
  mounted() {
    this.onWindowResize = throttle(this.onWindowResize, 200);
    this.calcDimensions();

    window.onresize = () => { this.onWindowResize(); };
    document.addEventListener("mouseup", this.onDocummentMouseUp);
    document.addEventListener("mousemove",this.onDocummentMouseMove);
  },

  beforeUnmount() {
    document.removeEventListener("resize",this.onWindowResize);
    document.removeEventListener("mouseup", this.onDocummentMouseUp);
    document.removeEventListener("mousemove",this.onDocummentMouseMove);
  },

  methods: {
    onWindowResize() {
      this.calcDimensions();
    },
    onDocummentMouseMove(e){
      if(this.disable || !this.isDragging) return;
      this.calculete(e);
    },
   
    onDocummentMouseUp() {
        if(this.disable) return;

        if(this.dragTimeout){
            clearTimeout(this.dragTimeout);
            this.$emit("click");
        }else{
            this.isDragging = false;
            this.$emit("dragend");

        }
    },
    onSliderClick(e){
      if(this.disable) return;

      this.calculete(e);
    },
    onSliderMouseDown() {
        if(this.disable) return;

        this.dragTimeout = setTimeout(() => {
            this.isDragging = true;
            this.dragTimeout = null;

            this.$emit("dragstar");

        }, 100);
    },

    calcDimensions() {
      const { bar, handle } = this.$refs;
      this.handleWidth = handle.offsetWidth;
      this.barWidth = bar.offsetWidth;
    },

    calculete(e){
      const {bar} = this.$refs;
      const {barWidth} = this;
      const relativeX = getRelativeXPosition(e,bar);
      const delta = relativeX /barWidth;

      this.$emit("input",lerp(this.min,this.max,delta));
    }
  },
};
</script>

<style>
 div {
  color: white;
 }
</style>
