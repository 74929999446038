<template>
  <section class="intro">
    <div class="intro__text">
      <div class="intro__instructions">
        <h1 class="intro__title">This is going to be fun</h1>
        <!-- <p>
          Press the buttons that appear during the <br />
          video to add to cart!
        </p> -->
      </div>
      <button class="intro__btn-play" @click="startPlay">Start</button>
    </div>
    <div class="intro__video">
      <img
        src="https://cdn.121corp.net/121/imgs/proactivity/pepsico/logos.svg"
        muted=""
        playsinline=""
        loop=""
        autoplay=""
      >
    </div>
  </section>
</template>

<script>
import "./BackgroundPepsico.scss";
export default {
  name: "BackgroundPepsico",
  emits: ["start"],
  setup(props, context) {
    function startPlay() {
      context.emit("start");
    }
    return { startPlay };
  },
};
</script>
