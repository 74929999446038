<template>
  <div class="interaction" :id="interaction.details.id">
    <div class="option__backgraound" />
    <div class="option">
      <button
        class="option__btn"
        v-for="option in interaction.details.options"
        :id="'option_' + `${option.id_option}`"
        :key="option"
        :points="option.points"
        :style="[
          sizeStyleButtons,
          {
            left: 'calc(100% - ' + option.left + '%)',
            bottom: 'calc(100% - ' + option.bottom + '%)',
          },
        ]"
        @click="selectedOption"
      >
        <span v-if="option.text"> {{ option.text }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import "@/components/OptionsInteractionVideo/OptionsInteractionVideo.scss";
import { computed } from "vue";
export default {
  name: "OptionsInteractionVideo",
  emits: ["show", "plus"],
  props: {
    id: Number,
    widthVideo: Number,
    heightVideo: Number,
    interaction: Object,
    extras: Object,
  },
  setup(props, context) {
    const sizeStyle = computed(() => {
      return {
        width: `calc( ${props.widthVideo}px / 2 * .45)`,
        height: `calc( ${props.heightVideo}px / 2 * .3)`,
        left: `${props.interaction.left}%`,
        bottom: `calc(100% - ${props.interaction.bottom}%)`,
      };
    });

    const sizeStyleButtons = computed(() => {
      return {
        width: `calc( 100% - 60%)`,
        height: `calc( 100% - 67% )`,
      };
    });

    function selectedOption(event) {
      let video = document.querySelector("video");
      video.currentTime = props.interaction.time_end + 0.1;
      context.emit("plus", Number(event.currentTarget.attributes.points.value));
      video.play();
    }

    return { sizeStyle, sizeStyleButtons, selectedOption };
  },
};
</script>
