<template>
  <div class="player">
    <div class="player__sizer">
      <video
        playsinline
        autoplay
        muted="true"
        webkit-playsinline="true"
        preload="metadata"
        ref="video"
        @play="onControlVideo"
        @pause="onControlVideo"
        @ended="winners"
        @click="togglePlay"
        @volumechange="onVideoVolumenChange"
        @loadedmetadata="onVideoLoadedMeta"
        @timeupdate="onVideoTimeUpdate"
        @progress="onVideoProgress"
        @loadstart="isLoading = true"
        @loadeddata="isLoading = false"
        @waiting="isWaiting = true"
        @canplay="isWaiting = false"
        @touchend="onTouchend"
      >
        <source :type="source.type" :src="require('@/assets/'+ source.src)" />
      </video>
    </div>
    <div class="player__gradient" />
    <div class="player__controls" :class="mobileCss">
      <SliderVideo
        class="player__seeker"
        :max="duration"
        :value="currentTime"
        @input="seek"
      >
        <template #bar>
          <div class="player__seeker-buffered" :style="bufferedStyle" />
        </template>
      </SliderVideo>
      <v-toolbar dense color="transparent" flat class="player__toolbar">
        <v-btn color="white" icon @click="togglePlay" id="play">
          <v-icon> {{ isPlaying ? "mdi-pause" : "mdi-play" }} </v-icon>
        </v-btn>

        <v-btn color="white" icon @click="toggleSound">
          <v-icon>
            {{ isMuted ? "mdi-volume-off" : "mdi-volume-high" }}
          </v-icon>
        </v-btn>

        <SliderVideo
          class="player__sound-slider mr-5"
          :style="{ width: '90px' }"
          :value="isMuted ? 0 : volume"
          @input="onVolumeSliderChange"
        />
        <div class="player__time">
          {{ currentTimeFormatted }} / {{ durationFormatted }}
        </div>

        <v-spacer />

        <v-btn color="white" icon @click="toggleFullVideo" v-if="isFullscreen">
          <v-icon>
            {{ isFullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}
          </v-icon>
        </v-btn>
      </v-toolbar>
    </div>

    <InteractionVideo 
    :interactions="interactions"
    :widthVideo="width"
    :heightVideo="height"
    @assign="wonVariantProduct"
    />

  </div>
</template>

<script>
import "@/components/PlayerVideo/PlayerVideo.scss";
import SliderVideo from "@/components/SliderVideo";
import InteractionVideo from "@/components/InteractionVideo";
var TimeFormat = require("hh-mm-ss");
import screenfull from "screenfull";
import { onMounted, ref, computed, onBeforeMount } from "vue";

export default {
  name: "PlayerVideo",
  components: { SliderVideo,InteractionVideo},
  emits: ["recomendation"],
  props: {
    source: {
      type: Object,
      requird: true,
      validator: (source) => source.type && source.src,
    },
    interactions: {
      type: Object,
    },
  },
  setup(props,context) {
    const isLoading = ref(false);
    const isWaiting = ref(false);
    const isPlaying = ref(false);
    const isGamePlay = ref(false);
    const isMuted = ref(false);
    const isFullscreen = ref(false);
    const isTouchend = ref(false);
    const video = ref(null);
    const currentTime = ref(0);
    const duration = ref(0);
    const width = ref(0);
    const height = ref(0);
    const buffered = ref(0);
    const volume = ref(1);
    const countProducts = ref(0);
    const total =ref(0);
    const winnersArrat = ref(null);
    var d = document.documentElement;

    onMounted(() => {
      width.value = video.value.offsetWidth;
      height.value = video.value.offsetHeight;

      window.onresize = () => {
        onWindowResize();
      };
      setTimeout(() => {
        document.querySelector("video").play();
      }, 200);
      document.addEventListener("keyup", onDocumentKeyUp);
      screenfull.onchange(onFullscreenChange);
    });

    onBeforeMount(() => {
      document.removeEventListener("resize", onWindowResize);
    });

    const mobileCss  = computed(() => {
      return isTouchend.value ? 'player__mobile' : '';
    });

    const currentTimeFormatted = computed(() => {
      return TimeFormat.fromS(Math.round(currentTime.value), "hh:mm:ss");
    });

    const durationFormatted = computed(() => {
      return TimeFormat.fromS(Math.round(duration.value), "hh:mm:ss");
    });

    const bufferedStyle = computed(() => {
      return {
        transformOrigin: "left center",
        transform: `scaleX(${buffered.value})`,
      };
    });

    function onControlVideo() {
      isPlaying.value = !isPlaying.value;
    }

    function onTouchend() {
      isTouchend.value = !isTouchend.value;
    }

    function onWindowResize() {
      width.value = video.value.offsetWidth;
      height.value = video.value.offsetHeight;
      d.style.setProperty('--vw', (d.clientWidth || window.innerWidth) / 100 + 'px');
      d.style.setProperty('--vh', (d.clientHeight || window.innerHeight) / 100 + 'px');
    }

    function onVideoLoadedMeta() {
      duration.value = video.value.duration;
      currentTime.value = video.value.currentTime;
    }

    function onVideoTimeUpdate() {
      currentTime.value = video.value.currentTime;
      document
        .querySelectorAll(".interaction")
        .forEach((element) => element.classList.remove("active"));

      props.interactions.forEach((element) => {
        if (
          element.time_start <= video.value.currentTime &&
          element.time_end >= video.value.currentTime
        ) {
          
          document.getElementById(element.details.id).classList.add("active");
          
          if (element.details.type == "stop") {
            video.value.pause();
            document.querySelectorAll('.'+element.details.extras[0].class).forEach((item) => {
              item.classList.add("active");
            });
          }
          if(element.details.type == "options"){
            video.value.pause();
          }
        }
      });
    }

    function onVideoVolumenChange() {
      isMuted.value = video.value.muted;
      volume.value = video.value.volume;
    }

    function onFullscreenChange() {
      isFullscreen.value = video.value.$el = screenfull.element;
    }

    function onDocumentKeyUp(e) {
      if (e.keyCode === 32) togglePlay();
    }

    function onVideoProgress() {
      const duration = video.value.duration;

      if (duration > 0) {
        const currentTime = video.value.currentTime,
          buffered = video.value.buffered;

        const bufferedLength = buffered.length;

        for (let i = 0; i < bufferedLength; i++) {
          const start = buffered.start(i);
          const end = buffered.end(i);

          if (start < currentTime && end > currentTime) {
            buffered.value = end / duration;
          }
        }
      }
    }

    function onVolumeSliderChange(value) {
      if (video.value.muted && value > 0) video.value.muted = false;

      if (value <= 0) video.value.muted = true;

      video.value.volume = value;
    }

    function toggleSound() {
      if (video.value.volume <= 0) {
        video.value.volume = 0.5;
      }

      video.value.muted = !video.value.muted;
    }

    function toggleFullVideo() {
      if (!screenfull.isEnabled) return;

      const element = video.value;

      if (screenfull.element == element) screenfull.exit();
      else screenfull.request(element);
    }

    function togglePlay() {
      video.value.paused ? video.value.play() : video.value.pause();
    }

    function seek(time) {
      currentTime.value = time;
      video.value.currentTime = time;
    }

    function recomendation(){
      context.emit("recomendation",Number(total.value));
    }

    function winners() {
      context.emit("recomendation",winnersArrat.value);
    }

    function assingnTotal(totalScore){
      total.value = totalScore;
    }

    function wonVariantProduct(variants){
      winnersArrat.value = variants;
    } 

    return {
      onWindowResize,
      onDocumentKeyUp,
      onVideoLoadedMeta,
      onControlVideo,
      onVideoTimeUpdate,
      onVideoVolumenChange,
      onVideoProgress,
      onVolumeSliderChange,
      onTouchend,
      toggleSound,
      toggleFullVideo,
      togglePlay,
      seek,
      recomendation,
      winners,
      assingnTotal,
      wonVariantProduct,
      currentTimeFormatted,
      bufferedStyle,
      mobileCss,
      durationFormatted,
      isLoading,
      isWaiting,
      isFullscreen,
      isPlaying,
      isGamePlay,
      isMuted,
      volume,
      video,
      width,
      height,
      duration,
      currentTime,
      countProducts,
    };
  },
};
</script>

<style>

.v-toolbar__content{
  height: 30px !important;
}

@media screen and (min-width: 844px){
  .v-toolbar__content {
    height: 27px;
  }
}



</style>
